#compare-options-panel {
    height: 100%;
    width: 100%;
}


#compare-search-button {
    width: 15%;
    border-radius: 4px;
    background: var(--defaultsecondary);
    color: var(--defaulttext);
    border: 1px solid var(--defaultborder);
    margin: 5px;
    outline: none;

    &:hover {
        border-radius: 4px;
        background: var(--defaulthover);
    }
}

.panel-card {
    padding: 10px;
    width: 100%;
}

#graph-id, #graph-id-graph-wrapper {
    height: 100%;
}

canvas {
    /*
    width: 100% !important;
    height: 100% !important;

     */
}

#svg-container-graph-id, svg[name=svg-container-graph-id] {
    height: 100% !important;
}

#plot-tabs {
    width: 100%;
    background: rgb(232, 232, 232);
}

.plot-tab {
    min-width: 20%;
}

#plot-set-tab-container {
    order: 1;
    flex-grow: 1.5;
    height: 100%;
}

#crystal-list-container {
    order: 2;
    flex-grow: 0;
    width: 320px;
    overflow: scroll;
    -ms-overflow-style: none;

    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
}


#plot-app-menu {
    background: var(--defaultsecondary);
    position: relative;
    width: 50px;
    float: right;
    z-index: 2;
    border: solid 2px var(--defaultborder);
    border-radius: 8px;
    margin-top: 10px;
    margin-right: 10px;
    color: var(--defaulttext);
}

.plot-app-menu-button {
    border: none;
    width: 46px;
    height: 50px;
    background: transparent;
    color: var(--defaulttext);

    &:hover {
        background: var(--defaultborder);
    }

    &:focus {
        color: var(--defaulthover);
    }
}

.dropdown-content-start {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: none;
    z-index: 1;
}

#plot-app-menu {
    .dropbtn {
        width: 47px;
        height: 50px;
        padding: 5px;
        background: transparent;

        &:hover {
            background: var(--defaultborder);
        }
    }

    .dropdown-content {
        right: 52px;
        top: 2px;
        border-radius: 8px;
        border: solid 2px var(--defaultborder);
        min-width: 120px;
        background: transparent;
        overflow: hidden;
        padding: 10px;
    }

    button {
        color: var(--defaulttext);
        background: transparent;

        &:hover {
            color: var(--defaulttext);
            background: var(--defaulthover);
        }
    }

    #graph-choices button {
        border: none;
        padding: 3px 3px 3px 9px;
        background: var(--defaultsecondary);
        text-align: left;
        overflow: hidden;
        color: var(--defaulttext);

        &:active {
            color: var(--defaulttext);
        }
    }
}

button:hover {
    color: var(--defaulttext);
}

#plot-app-menu {
    .dropdown-content button {
        &:hover {
            background: var(--defaulthover);
            overflow: hidden;
        }

        &:focus {
            color: #39b7f3;
        }
    }

    .dropdown {
        height: auto;
    }

    p {
        font-size: 11px;
        margin-bottom: 4px;
    }

    .dropdown-content:hover {
        display: block;
        background: var(--defaultsecondary);
    }
}

.dropdown-content:focus {
    display: block;
    background: var(--defaultsecondary);
}

#plot-app-menu {
    .dropbtn:hover + .dropdown-content {
        background: var(--defaultsecondary);
    }

    .dropdown {
        &:active .dropdown-content, &:focus .dropdown-content {
            display: block;
        }
    }
}

.graph-control-button {
    width: 60px;
    display: inline-block;
    padding: 5px;
    border: 2px solid var(--defaultborder);
    border-radius: 5px;
    margin-left: 5px;
}

#search-facet {
    width: 97%;
    margin-top: 3px;
    background: var(--defaultsecondary);
    border: solid 1px var(--defaultborder);
    color: var(--defaulttext);
    margin-bottom: 10px;
}

#search-panel-settings {
    padding: 10px;
}

#search-k, #search-result-size {
    width: 98%;
}

#plot-app > div > div > svg {
    position: absolute;
}

div.react-sigma-v2 {
    background: transparent !important;
}

#current-crystal-list {
    overflow: auto;
    overflow-x: hidden;
}

.open-plot-app-menu {
    width: 20px;
    display: grid;
    height: 100vh;
    z-index: 1;
    float: right;
    position: relative;
    background: var(--defaultsecondary);
    box-shadow: 0 0 6px 1px var(--defaultshadow);
    align-content: center;
    justify-content: center;

    &.clicked {
        background: transparent;
        border: none;

        &:hover {
            background: transparent;
            cursor: auto;
        }
    }

    &:hover {
        cursor: pointer;
        background: var(--defaulthover);
    }
}

.app-menu {
    position: fixed;

    /* Stay in place */
    z-index: 10;

    /* Sit on top */
    height: 98vh;
    color: var(--defaulttext);
    background: var(--defaultsecondary);
    border-radius: 10px;
    box-shadow: 0 0 6px 2px var(--defaultshadow);
    padding: 10px;
    right: -100%;

    /*Put it on the very top*/
    transition: all .3s ease-in-out;

    /*make it smooth*/
    top: 10px;
    width: 25%;

    &.open {
        right: 1%;
    }
}

.app-menu-container {
    overflow-y: auto;
    scrollbar-width: thin;
    height: 95%;
    width: 100%;
    padding: 15px;

    input[type=range] {
        width: 95%;
    }

    &::-webkit-scrollbar {
        width: 2px;
        background: var(--defaultsecondary);
    }

    > {
        h6 {
            font-style: italic;
            font-size: 12px;
            border-bottom: solid 1px var(--defaultborder);
            padding: 3px;
            margin-top: 10px;
        }

        p {
            font-size: 11px;
            margin-bottom: 3px;
        }
    }
}

.plot-app-menu-graph-selection-button {
    background: var(--defaultprimary);
    border-radius: 10px;
    color: var(--defaulttext);
    display: grid;
    align-content: center;
    justify-content: center;
    font-size: .7rem;
    margin: 5px;
    box-shadow: 0 0 3px 1px var(--defaultshadow);
    padding: 25px 2px;

    &:hover {
        box-shadow: 0 0 6px 2px var(--defaultshadow);
    }

    &.selected {
        background: var(--defaultborder);
    }
}

.plot-app-menu-graph-selection {
    display: grid;
    background: transparent;
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 50% 50%;
    min-height: 20%;
}

.compare-app {
    display: grid;
    grid-template-areas:
        "top-bar"
        "main";

    height: 100%;
    $top-bar-height: 50px;
    grid-template-rows: $top-bar-height calc(100% - #{$top-bar-height});

    scrollbar-color: var(--defaulttext) var(--defaultborder);

    &.hidden {
        display: none;
    }

    button {
        color: var(--defaulttext);

        &:hover {
          color: var(--defaulthover);
        }
    }

    button.active {
        border: 1px solid var(--defaultborder);
    }

    .compare-app-top-bar {
        height: 50px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        grid-area: top-bar;
        border-bottom: 1px solid var(--defaultborder);
        align-items: center;
        z-index: 1;
        background: var(--defaultprimary);

        button {
            background: transparent;
            border: none;

            &.active {
                background: var(--defaulttertiary);
                border-radius: 5px;
            }
        }

        .search-bar {
            display: flex;
        }

        .search-facet {
            background: transparent;
            border: 1px solid var(--defaultborder);
            border-radius: 5px;
            color: var(--defaulttext);

            option {
                background: var(--defaultprimary);
                color: var(--defaulttext);
            }
        }

        .left-content {
            display: flex;
            align-content: flex-start;
            align-items: center;
            >p {
                margin: 0 10px;
            }
        }

        .right-content {
            display: flex;
            align-content: flex-end;
            align-items: center;
            > * {
                margin: 0 5px;
            }
        }

        .compare-search-query {
            color: var(--defaulttext);
            background: var(--defaultprimary);
            border: 1px solid var(--defaultborder);
            border-radius: 5px;

            &:focus {
                outline: 2px solid var(--defaultborder);
            }
        }
    }

    .compare-app-main {
        grid-area: main;
        display: flex;

        .top-filter {
            width: 100%;
            background: var(--defaultprimary);
            color: var(--defaulttext);
            border: 1px solid var(--defaultborder);
            padding: 0 5px;
            border-radius: 5px;

            &:focus {
                outline: var(--defaulttext);
            }
        }

        .search-panel {
            order: 0;
            height: 100%;
            width: 0;
            color: var(--defaulttext);
            background: var(--defaultprimary);
            padding: 0;
            /*Put it on the very top*/
            -webkit-transition:width .2s linear;
            transition: width .2s linear;
            -webkit-transform:translateZ(0) scale(1,1);
            /*make it smooth*/
            overflow: auto;
            z-index: 2;
            border-right: 1px solid var(--defaultborder);
            scrollbar-width: none;

            &.open {
                width: 20%;
            }

            .search-results-container {
                background: var(--defaultprimary);
                color: var(--defaulttext);
                scrollbar-width: thin;
                width: 100%;
                overflow: auto;
                border-bottom: 1px solid var(--defaultborder);
            }
        }

        .compare-app-plot {
            background: var(--defaulttertiary);
            order: 1;
            height: 100%;
            max-width: 100%;
            flex-grow: 3;
            overflow: hidden;

            > div {
                height: 100%;
                width: 100%;

                > .force-graph-container {
                    height: 100%;
                }
            }


            .preview-panel {
                display: none; /* Hidden by default */
                position: fixed; /* Stay in place */
                z-index: 2; /* Sit on top */
                width: 16%;
                height: 22%;
                min-height: fit-content;
                color: var(--defaulttext);
                background: var(--defaultprimary);
                border-radius: 10px;

                padding: 5px;
                box-shadow: 0 0 5px 2px var(--defaultshadow);

                .preview-title {
                    margin-bottom: 0;
                }

                .top-container {
                    display: flex;
                    justify-content: space-between;
                    font-size: 10px;
                    border-bottom: 1px solid var(--defaultborder);

                    button {
                        background: transparent;
                        color: var(--bs-blue);
                        border: none;
                    }

                    .left-content {
                        display: flex;
                        align-content: flex-start;
                        align-items: center;
                    }

                    .right-content {
                        display: flex;
                        align-content: flex-end;
                        align-items: center;
                    }
                }
            }



            .preview-title {
                font-size: 11px;
                font-style: italic;
                float: left;
                margin-bottom: 5px;
                margin-left: 10px;
                color: var(--defaulttext);
            }

            .preview-panel-canvas {
                width: 100% !important;
                height: 90% !important;
            }
        }

        .preview-list {
            order: 2;
            width: 0;
            z-index: 1;
            -webkit-transition:width .2s linear;
            transition:width .2s linear;
            -webkit-transform:translateZ(0) scale(1,1);
            overflow: hidden;

            &.open {
                width: 15%;
                border-left: 1px solid var(--defaultborder);
                overflow: auto;
                scrollbar-width: thin;
                padding: 10px;
            }

            .preview-list-item {
                border-radius: 10px;
                border: 1px solid var(--defaultborder);
                margin: 5px 0;

                button {
                    background: transparent;
                    color: var(--bs-blue);
                    border: none;
                }

                .top-container {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    border-bottom: 1px solid var(--defaultborder);
                    padding: 4px;

                    p {
                        margin-bottom: 0;
                    }

                    .left-content {
                        display: flex;
                        align-content: flex-start;
                        align-items: center;
                    }

                    .right-content {
                        display: flex;
                        align-content: flex-end;
                        align-items: center;
                    }
                }
            }
        }

        .crystal-list {
            order: 3;
            width: 0;
            z-index: 1;
            -webkit-transition:width .2s linear;
            transition:width .2s linear;
            -webkit-transform:translateZ(0) scale(1,1);
            background: var(--defaultprimary);
            &.open {
                width: 12%;
                border-left: 1px solid var(--defaultborder);
                overflow: auto;
                scrollbar-width: thin;
                padding: 10px;
            }
        }

        .compare-app-menu {
            order: 4;
            width: 0;
            z-index: 1;
            -webkit-transition:width .2s linear;
            transition:width .2s linear;
            -webkit-transform:translateZ(0) scale(1,1);
            background: var(--defaultprimary);

            select {
                width: 100%;
            }

            &.open {
                width: 18%;
                border-left: 1px solid var(--defaultborder);
                overflow: auto;
                scrollbar-width: thin;
                padding: 10px;
            }
        }
    }
}