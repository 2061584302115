

.home-app {
  background: var(--defaulttertiary);
  min-height: 100%;
  display: flex;

  .home-app-create-accordion {

    box-shadow: 0 0 3px 1px #1c1b26;

    .create-accordion-header {
      height: 0;
      overflow: hidden;
      transition: height 0.1s ease-in-out;

      button {
        font-size: 13px;
      }

      p {
        font-size: 13px;
        margin-bottom: 3px;
      }

      &.open {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        height: 100px;
        background: var(--defaultprimary);
        margin: 10px 10px 0;
        border-bottom: 1px solid var(--defaultborder);
      }
    }

    .create-accordion-body {
      height: 0;
      overflow: hidden;
      transition: height 0.1s ease-in-out;

      .tables {
        display: flex;
      }

      table {
        font-size: 12px;
        margin: 5px 10px;
        height: 300px;
        display: block;
        overflow: auto;
        scrollbar-width: thin;
        width: 100%;

        th, td {
          padding: 0 15px;
        }
      }

      .data-item {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
      }

      &.open {
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        height: 400px;
        background: var(--defaultprimary);
        margin: 0 10px 0;
        border-bottom: 1px solid var(--defaultborder);
      }

      .top-bar {
        font-size: 11px;
        height: auto;
        margin-bottom: 10px;
        padding-left: 0;
        justify-content: start;


        button {
          outline: none;
          border: none;
          color: var(--defaulttext);
          border-bottom: 2px solid transparent;
          background: transparent;
          padding: 10px 30px 10px;

          &:hover {
            border-bottom: 2px solid var(--defaultborder);
          }

          &.active {
            border-bottom: 2px solid var(--bs-blue);
          }
        }
      }
    }
  }

  .home-app-main {
    order: 0;
    background: var(--defaulttertiary);
    overflow: auto;
    min-height: 100%;
    flex-grow: 1;

    .subset-accordion-list {
      overflow: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      height: 75vh;

      &.hidden {
        display: none;
      }
    }

    .crystal-table {
      font-size: 13px;
      border: 1px solid var(--defaultborder);
      width: 100%;

      th, td {
        padding: 5px;
      }
    }

    .crystal-accordion-list {
      height: 70vh;
      overflow: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &.hidden {
        display: none;
      }
    }

    .crystal-accordion-list::-webkit-scrollbar {
      display: none;
    }

    .search-accordion {
      background: var(--defaulttertiary);
      margin: 5px;
      transition: 0.4s;
      padding: 10px;
      border: 1px solid var(--defaultborder);
      border-radius: 5px;

      p {
        font-size: 12px;
        color: var(--defaulttext);
        margin-bottom: 5px;
      }

      #advanced-search-button {
        background: transparent;
        color: var(--defaulttext);
        outline: none;
        font-size: 13px;
        border: none;

        &:hover {
          color: var(--bs-blue)
        }

        &:focus {
          color: var(--bs-blue)
        }
      }

      .search-accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-right: 30px;
        font-size: 13px;

        .search-accordion-button {
          font-size: 13px;
          background: var(--bs-blue);
          color: var(--defaulttext);
          outline: none;
          border: none;
          border-radius: 3px;
          padding: 1px 10px;

          &:hover {
            color: var(--secondarytext);
          }
        }

        .group {
          display: flex;
          align-items: first baseline;
          >* {
            margin-right: 10px;
          }
        }
      }

      .search-accordion-body {
        height: 0;
        overflow: hidden;
        transition: height 0.2s ease-out;

        &.open {
          display: flex;
          height: 65px;
          padding: 5px 10px;
        }
      }
    }

    .accordion {
      background: var(--defaultprimary);
      margin: 10px;
      box-shadow: 0 0 3px 1px #1c1b26;
      padding: 10px;

      .accordion-body {
        height: 0;
        overflow: hidden;
        transition: height 0.1s ease-in-out;
        padding: 0;

        &.open {
          height: 300px;
          padding: 15px;
        }
      }


      .accordion-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 20px;

        .group-1 {
          display: flex;
          align-items: first baseline;
          min-width: 20%;
          >* {
            margin-right: 20px;
          }
        }

        p{
          margin-bottom: 3px;
          font-size: 14px;
        }

        .mb-3 {
          margin-bottom: 0 !important;
        }
      }
    }


    .crystal-accordion {
      background: var(--defaultprimary);
      margin: 10px;
      box-shadow: 0 0 3px 1px #1c1b26;
      transition: 0.4s;
    }

    .crystal-accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-right: 30px;
      font-size: 13px;

      .group-1 {
        display: flex;
        align-items: first baseline;
        min-width: 20%;
        >* {
          margin-right: 20px;
        }
      }

      p {
        margin: 1em .5em;
      }

      .button-set {
        display: flex;
        >* {
          margin-left: 10px;
        }
      }

      &:hover {
        color: var(--secondarytext);
      }

      >* {
        margin-left: 30px;
      }

      &.open {
        color: var(--secondarytext);
        border-bottom: 1px solid var(--defaultborder);
      }
    }

    .crystal-accordion-body {
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease-out;

      .data-item {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
      }

      .top-bar {
        font-size: 11px;
        height: auto;
        margin-bottom: 10px;
        padding-left: 0;
        justify-content: start;

        button {
          padding: 15px 20px 5px;
        }
      }

      .part-1 {
        min-width: 60%;
        padding-left: 30px;

        .top-bar {
          button {
            outline: none;
            border: none;
            color: var(--defaulttext);
            border-bottom: 2px solid transparent;
            background: transparent;
            padding: 10px 30px 10px;

            &:hover {
              border-bottom: 2px solid var(--defaultborder);
            }

            &.active {
              border-bottom: 2px solid var(--bs-blue);
            }
          }
        }

        .data-item {
          >p {
            margin-bottom: 0;
          }
        }

        .unitcell-info {
          width: 65%;
          overflow: auto;
          scrollbar-width: thin;

          p {
            margin-bottom: 5px;
            font-size: 13px;
          }
        }

        .amd-info {
          width: 45%;
          overflow: auto;
          scrollbar-width: thin;

          p {
            margin-bottom: 5px;
            font-size: 13px;
          }
        }

        .pdd-info {
          width: 70%;
          overflow: auto;
          scrollbar-width: thin;

          p {
            margin-bottom: 5px;
            font-size: 13px;
          }
        }

        .symmetry-info {
          width: 30%;
          overflow: auto;
          scrollbar-width: thin;

          p {
            margin-bottom: 5px;
            font-size: 13px;
          }
        }

        .tables {
          display: flex;
          height: 85%;
          justify-content: space-between;

          tbody > tr {
            &:hover {
              color: var(--secondarytext);
            }
          }

          >* {
            margin-right: 20px;
          }

          tr {
            border-width: 1px;
          }


          .atom-info {
            width: 55%;
            overflow: auto;
            scrollbar-width: thin;

            p {
              margin-bottom: 5px;
              font-size: 13px;
            }
          }

          .bond-info {
            width: 35%;
            overflow: auto;
            scrollbar-width: thin;

            p {
              margin-bottom: 5px;
              font-size: 13px;
            }
          }

        }

        .amd-table {
          font-size: 12px;
          border: 1px solid var(--defaultborder);
          width: 100%;
        }

        .pdd-table {
          font-size: 12px;
          border: 1px solid var(--defaultborder);
          width: 100%;
        }

        .unitcell-table {
          font-size: 12px;
          border: 1px solid var(--defaultborder);
          width: 100%;
        }

        .symmetry-table {
          font-size: 12px;
          border: 1px solid var(--defaultborder);
          width: 100%;
        }

        .atom-table {
          width: 100%;
          font-size: 12px;
          border: 1px solid var(--defaultborder);
          overflow: auto;
          max-height: 70%;
          scrollbar-width: thin;
        }

        .bond-table {
          width: 100%;
          font-size: 12px;
          border: 1px solid var(--defaultborder);
          overflow: auto;
          max-height: 70%;
          scrollbar-width: thin;

        }
      }

      .part-2 {
        width: 33%;
        margin: 10px;

      }

      &.open {
        display: flex;
        height: 400px;
      }
    }

    .search-bar {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;
      border-bottom: solid 1px var(--defaultborder);

      .left-group {
        display: flex;
      }
      .right-group {
        display: flex;
      }
    }

    .accordion {
      background: var(--defaultprimary);
    }

    .molecule-card-row {
      width: 100%;
      min-height: 300px;
      padding: 30px 50px;
      display: flex;
      justify-content: center;
    }

    .molecule-card {
      min-height: 100%;
      border-radius: 10px;
      background: var(--defaultprimary);
      box-shadow: 0 0 3px 2px var(--defaultshadow);
      min-width: 20%;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
      &:hover {
        box-shadow: 0 0 3px 3px var(--defaultshadow);
      }
    }

    .top-bar {
      width: 100%;
      display: flex;
      padding: 0 10px;
      justify-content: space-between;
      margin-bottom: 10px;

      .left-group {
        display: flex;
        font-size: 14px;
        button {
          outline: none;
          border: none;
          color: var(--defaulttext);
          border-bottom: 2px solid transparent;
          background: transparent;
          padding: 10px 30px 10px;

          &:hover {
            border-bottom: 2px solid var(--defaultborder);
          }

          &.active {
            border-bottom: 2px solid var(--bs-blue);
          }
        }
      }
      .right-group {
        display: flex;
        padding: 5px 10px;

        button {
          padding: 5px 15px;
          background: var(--bs-blue);
        }
      }

    }

  }

  &.hidden {
    display: none;
  }

}