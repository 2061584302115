body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100vh;
  overflow: hidden;
}

body {
  min-height: 100vh;
  padding: 0;
}


@mixin gloss-white {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #f3f3f3 50%, #ededed 62%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%,#f3f3f3 50%,#ededed 62%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%,#f3f3f3 50%,#ededed 62%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

$primary: #111827;

body > #root > div {
  height: 100vh;
  overflow: hidden;
  --defaultsecondary: #24262a;
  --defaulthover: #7d8595;
  --defaulttext: #e0e6f0;
  --secondarytext: #9faab9;
  --defaultIcon: #673ab7;
  --defaultprimary: #222428;
  --defaulttertiary: #1d1f22;
  --defaultborder: #24272d;
  --defaultshadow: #151414;
  color: var(--defaulttext)
}


#login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--defaulttertiary);
  color: var(--defaulttext);
}

#login-form {
  height: 330px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border-radius: 5px;
}

#submit-login-button, #submit-create-button {
  width: 300px;
  color: var(--defaulttext);
  margin-top: 10px;
  padding: 5px 0;
}

#login-tabs > nav {
  margin-top: 10px;
}


#login-tabs {
  nav button {
    border: none;
    width: 200px;
    padding: 10px;
    border-bottom: 2px solid var(--defaultprimary);
    color: var(--defaulttext);
    background: var(--defaultprimary);

    &:hover {
      border-bottom: 2px solid var(--defaulthover);
    }

    &:focus {
      border-bottom: 2px solid var(--defaultborder);
    }
  }

  width: 400px;
  height: 460px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--defaultprimary);
  border-radius: 6px;
  color: var(--defaulttext);
}

fieldset {
  p {
    margin-bottom: 1px;
    margin-top: 5px;
  }

  input {
    border: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
    background: var(--defaultprimary);
    height: 30px;
    padding-left: 10px;
    width: 300px;
    margin-bottom: 10px;

    &:focus {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.19);
      border: none;
      outline: none;
    }
  }
}

.p-4 {
  border-top: 1px solid var(--defaultborder);
  height: 350px;

  form {
    margin-left: 26px;
  }
}

#main-title {
  text-align: center;
  font-family: monospace;
  margin-bottom: 0px;
}

#login-tabs input {
  background: var(--defaultprimary);
  border-radius: 5px;
  color: var(--defaulttext);
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

select {
  border-radius: 5px;
  border: 1px solid var(--defaultborder);
  background: transparent;
  outline: none;
  color: var(--defaulttext);
}

input[type="text"] {
  color: var(--defaulttext);
  background: var(--defaultprimary);
  border: 1px solid var(--defaultborder);
  border-radius: 5px;

  &:focus {
    outline: 2px solid var(--defaultborder);
  }
}

label {
  color: var(--defaulttext);
  font-size: 12px;
  margin-bottom: 1px;
}