.App {
  display: grid;
  grid-template-areas:
    'header'
    'main';
  grid-gap: 0;
  padding: 0;
  grid-template-columns: 100vw;
  grid-template-rows: 45px calc(100vh - 45px);
}

#main-header {
  background: var(--defaultprimary);
}


.app-header {
  background: var(--defaultprimary);
  display: flex;
  grid-area: header;
  z-index: 100;
  box-shadow: 1px 0 4px 1px var(--defaultshadow);
  justify-content: space-between;

  .content-left {
    order: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    p {
      margin-bottom: 0;
    }
  }

  .content-right {
    order: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    > * {
      margin-left: 20px;
    }
  }

  .header-button {
    background: transparent;
    border: none;
    height: 100%;
    font-size: 11px;
    padding: 3px;
    color: var(--defaulttext);
    &:hover {
      color: var(--defaulthover);
    }

  }

}



.show>.dropdown-menu {
  display: block;
  position: absolute;
}

#TopMenuBar .dropdown-content > button {
  border: none;
  width: 160px;
  text-align: left;
  padding: 3px 3px 3px 18px;
}

.dropbtn {
  background-color: var(--defaultprimary);
  color: var(--defaulttext);
  font-size: 16px;
  border: none;
  padding: 8px 20px 7px 20px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  float: left;
  height: 39px;
}
/* Dropdown Content (Hidden by Default) */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  color: var(--defaulttext);
  z-index: 1;

  a {
    color: var(--defaulttext);
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: var(--defaultborder);
    }
  }
}

/* Links inside the dropdown */

/* Change color of dropdown links on hover */

/* Show the dropdown menu on hover */

.dropdown:hover {
  .dropdown-content {
    display: block;
  }

  .dropbtn {
    background-color: var(--defaultborder);
  }
}

/* Change the background color of the dropdown button when the dropdown content is shown */
#Settings-button {
  background: red;
}

#footer-settings-button {
  height: 40px;
  background: transparent;
  float: left;
  color: rgb(75, 75, 75);
  border: none
}

#footer-settings-button:hover  {
  color: var(--defaulthover)
}

.settings-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* settings-modal Content/Box */
.settings-modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 700px; /* Could be more or less, depending on screen size */
  height: 500px;
  top: 20%;
  position: relative;
  left: 30%;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#footer-bar {
  grid-area: footer;
}

#side-bar-logo {
  margin-top: 10px;
  margin-bottom: 120px;
  height: 90px;
  padding: 5px;
}


.create-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: -200%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: inherit;
  transition: all .5s ease-in-out;
}

.create-modal.open {
  left: 0;
  top: 0;
}


/* settings-modal Content/Box */
.create-modal-content {
  background-color: var(--defaultprimary);
  padding: 20px;
  border: 1px solid var(--defaultborder);
  border-radius: 8px;
  width: 700px; /* Could be more or less, depending on screen size */
  height: 500px;
  top: 20%;
  position: relative;
  left: 30%;
  color: var(--defaulttext);
  box-shadow: 0 0 6px 3px var(--defaultshadow);
}

.create-crystal-panel {
  padding: 10px;
}

.create-crystal-panel input[type=file]::file-selector-button {
  border: 1px solid var(--defaultborder);
  color: var(--defaulttext);
  background: var(--defaultprimary);
  border-radius: 5px;
}

.create-crystal-panel input[type=file]::file-selector-button:hover {
  background: var(--defaulthover);
  cursor: pointer;
}

.create-crystal-panel > h6 {
  font-size: 12px;
  font-style: italic;
  color: var(--defaulttext)
}

.create-crystal-options {
  display: grid;
  grid-template-areas:
    "meta geometry";
  grid-template-columns: 30% 70%;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--defaultborder);
  outline: 0;
  font-size: 0.7rem;
  color: var(--defaulttext);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.5rem;
  color: var(--defaulttext);
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.5rem;
    color: var(--defaulttext);
    font-weight:500;
  }
  padding-bottom: 6px;
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, var(--defaulthover), var(--defaultborder));
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

.create-crystal-options-meta {
  grid-area: meta;
}

.create-crystal-options-geometry {
  grid-area: geometry;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  background-color: var(--defaultprimary); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav.open {
  width: fit-content;
  padding: 20px;
  border-right: 1px solid var(--defaultborder)
}

@keyframes displaceContent {
  from { transform : translateY(0em) }
  to   { transform : translateY(3em) } /* slide down to make room for advertisements */
}


/* FADE ANIMATIONS */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.close-button-container {
  width: 100%;
  height: 20px;
}

.close-button-container > button {
  float: right;
  color: var(--defaulttext);
  background: transparent;
  border: none;
}


#search-panel-settings p {
  font-size: 12px;
  margin-bottom: 2px;
}


#view-panel {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  width: 700px;
  height: fit-content;
  color: var(--defaulttext);
  background: var(--defaultprimary);
  border-radius: 10px;
  border: solid 1px var(--defaultborder);
  padding: 5px;
  right: 10px;
  bottom: 10px;
}

.view-title {
  font-size: 12px;
  font-style: italic;
  float: left;
  margin-bottom: 5px;
  margin-left: 10px;
}

#view-panel-canvas {
  margin: 15px;
  width: 95%;
  height: 300px;
  border-bottom: 1px solid var(--defaultborder);
}


input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &::-moz-range-thumb {
    border: none;
  }
}

.range-style {
  height: 25px;
  padding: 10px;
  background: var(--defaultprimary);
  border-radius: 10px;
  box-shadow: -2px -2px 8px var(--defaultshadow), 2px 2px 8px rgba(var(--defaultshadow), 0.5);
  margin: 2px;
  @mixin track-style {
    display: flex;
    align-items: center;
    height: 5px;
    border-radius: 10px;
    box-shadow: inset -2px -2px 8px var(--defaulttext), inset 2px 2px 8px rgba(black, 0.5);
  }

  @mixin thumb-style {
    position: relative;
    top: -50%;
    width: 12px;
    height: 12px;
    background-color: #8e95a6;
    background-image: linear-gradient(-45deg, rgba(var(--defaulttext), 0.3), transparent);
    border-radius: 50%;
    box-shadow: -1px -1px 2px var(--defaultshadow), 1px 1px 2px rgba(var(--defaultshadow), 0.3);
  }
  &::-webkit-slider-runnable-track {
    @include track-style;
  }
  &::-webkit-slider-thumb {
    @include thumb-style;
  }
  &::-moz-range-track {
    @include track-style;
  }
  &::-moz-range-thumb {
    @include thumb-style;
  }
}