

.query-result {
  width: 100%;
  display: grid;
  background: var(--defaultprimary);
  color: var(--defaulttext);
  border-top: 1px solid var(--defaultborder);
  grid-template-areas: 'chev name name add' 'chev id1 id2 add';
  align-content: center;
  padding: 8px 15px;
  justify-content: space-between;
}

.query-result-dropdown {
  width: 100%;
  display: grid;
  background: var(--defaulttertiary);
  transition: all 500ms ease;
  grid-template-areas: 'field value' 'add add';
  grid-template-rows: 100px 50px;
  grid-template-columns: 50% 50%;
  padding: 10px 15px;

  .query-result-dropdown-button-row {
    display: flex;
    align-content: space-between;
    button {
      width: 50%;
      border: 1px solid var(--defaultborder);

      &:hover {
        color: var(--defaulthover);
      }

      &.disabled {
        color: var(--defaulthover);
        cursor: default;
      }
    }
  }
  .query-result-dropdown-data {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      padding: 2px;
      word-break: break-word;
    }

    .query-result-dropdown-data-label {
      min-width: fit-content;
      color: var(--secondarytext);
    }

    query-result-dropdown-data-value {
      max-width: 50%;
      word-break: break-word;
      p {
        max-width: 50%;
        word-break: break-word;
      }
    }
  }
}

.query-result-accordion {
  transition: all 500ms ease;
}

.query-result-name {
  grid-area: name;
  font-size: 1rem;
  color: var(--defaulttext);
  margin: 0;
}

.query-result-name-with-sim {
  grid-area: name;
  color: var(--defaulttext);

  > {
    h4 {
      font-size: .9rem;
      margin-bottom: 0;
    }

    p {
      font-size: .7rem;
      margin-bottom: 0;
    }
  }
}

.query-result-open-button {
  grid-area: chev;
  color: var(--defaulttext);
  border: none;
  background: var(--defaultprimary);
}

.query-result-quick-add-button {
  color: var(--defaulttext);
  background: var(--defaultprimary);
  grid-area: add;
  border: none;
}

.query-result-add-button:hover {
  color: #39b7f3;
  background: var(--defaultborder);
  border-radius: 4px;
  border: 2px solid darkgray;
}

.query-result-id {
  grid-area: id1;
  font-size: .7rem;
  margin: 0;
}

.query-result-source {
  grid-area: id2;
  font-style: italic;
  font-size: .7rem;
  margin: 0;
}

.query-result-added {
  display: inline-grid;
  height: 45px;
  width: 180px;
  grid-template-areas: "remove name";
  grid-template-columns: 50px 250px;
  background: var(--defaultprimary);
  color: var(--defaulttext);
  border: 1px solid var(--defaultborder);
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
}

.query-result-remove-button {
  grid-area: remove;
  height: 25px;
  width: 30px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid var(--defaultborder);
  color: var(--bs-blue);

  &:hover {
    background: var(--defaultborder);
  }
}

.query-result-dropdown-button {
  height: 25px;
  color: var(--defaulttext);
  border: 2px solid var(--defaultborder);
  border-radius: 5px;
  display: inline;
  grid-area: add;
  overflow: hidden;
  font-size: 11px;
  margin-left: 3px;
  background: var(--defaultprimary);

  &:hover {
    color: var(--defaulthover);
  }
}

.query-result-dropdown p {
  font-size: 12px;
  margin-bottom: 1px;
  text-align: center;
}

.search-query {
  color: var(--defaulttext);
  background: var(--defaultprimary);
  border: 1px solid var(--defaultborder);
  border-radius: 5px;

  &:focus {
    outline: 2px solid var(--defaultborder);
  }
}