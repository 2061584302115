
.create-app {
  display: grid;
  grid-template-areas:
        "top-bar"
        "main";

  height: 100%;
  $top-bar-height: 50px;
  grid-template-rows: $top-bar-height calc(100% - #{$top-bar-height});

  scrollbar-color: var(--defaulttext) var(--defaultborder);

  &.hidden {
    display: none;
  }

  button {
    color: var(--defaulttext);
    background: transparent;
    border: none;

    &:hover {
      color: var(--defaulthover);
    }
  }

  button.active {
    border: 1px solid var(--defaultborder);
  }

  .create-app-top-bar {
    height: 50px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    grid-area: top-bar;
    border-bottom: 1px solid var(--defaultborder);
    align-items: center;
    z-index: 1;

    .left-content {
      display: flex;
      align-content: flex-start;
      align-items: center;
      >p {
        margin: 0 10px;
      }

      height: 100%;
      .create-app-tabs {
        height: 100%;
        border-bottom: 1px solid var(--defaultborder);
      }
      .create-app-tab-button {
        border: none;
        width: 110px;
        outline: none;
        color: var(--defaulttext);
        background: transparent;
        border-bottom: 2px solid transparent;
        font-size: 14px;
        height: 100%;

        &.selected {
          border: none;
          width: 110px;
          outline: none;
          color: var(--defaulttext);
          background: transparent;
          border-bottom: 2px solid var(--defaulttext);
          font-size: 14px;
        }

        &:hover {
          border-bottom: 2px solid var(--defaulthover);
        }

        &:focus {
          border-bottom: 2px solid var(--defaulttext);
        }
      }
    }

    .right-content {
      display: flex;
      align-content: flex-end;
      align-items: center;
      > * {
        margin: 0 5px;
      }
    }
  }


  .create-app-main {
    grid-area: main;
    display: flex;
    background: var(--defaulttertiary);

    .input-item {
      margin-bottom: 5px;
      margin-top: 3px;
    }

    .button-switch {
      display: flex;
      margin: 10px 5px 5px;

      .switch-button {
        margin: 3px;
        color: var(--defaulttext);
        border: 2px solid var(--defaulttext);
        border-radius: 5px;
        background: var(--defaultprimary);

        &:hover {
          color: var(--defaultborder);
          border: 2px solid var(--defaultborder);
        }

        &.active {
          color: var(--defaultborder);
          border: 2px solid var(--defaultborder);
        }
      }
    }

    .checked-input {
      display: flex;
    }

    .creation-progress {
      display: grid;
      justify-content: center;
      align-content: center;
      width: 100%;
      justify-items: center;
      margin-top: 10px;
    }

    .top-filter {
      width: 100%;
      background: var(--defaultprimary);
      color: var(--defaulttext);
      border: 1px solid var(--defaultborder);
      padding: 0 5px;
      border-radius: 5px;

      &:focus {
        outline: var(--defaulttext);
      }
    }

    .create-tab-content {
      display: none;
      width: 100%;
      height: 100%;
      padding: 20px;

      .create-panel-text-area {
        width: 100%;
        height: 50%;
        border-radius: 5px;
        background: transparent;
        color: var(--defaulttext);
        border: 1px solid var(--defaultborder);
        margin-top: 10px;
        margin-bottom: 10px;
      }



      .create-subset-panel {
        width: 30%;
        border-radius: 5px;
        box-shadow: 0 0 5px 2px var(--defaultshadow);
        padding: 30px;
        border: 1px solid var(--defaultborder);

        input {
          width: 100%;
        }

        button {
          width: 100%;
          border: 2px solid var(--defaultborder);
          color: var(--defaulttext);

          &.disabled {
            color: var(--defaultborder);
            cursor: default;
          }
        }
      }

      .create-tab-crystal-meta-panel {
        width: 25%;
        border-radius: 5px;
        box-shadow: 0 0 5px 2px var(--defaultshadow);
        padding: 20px;
        border: 1px solid var(--defaultborder);
        background: var(--defaultprimary);

        input {
          width: 100%;
        }

        button {
          width: 100%;
          border: 2px solid var(--defaultborder);
          color: var(--defaulttext);

          &.disabled {
            color: var(--defaultborder);
            cursor: default;
          }
        }

        .create-crystal-options-button {
          width: 100%;
          border: 2px solid var(--defaultborder);
          color: var(--defaulttext);

          &.disabled {
            color: var(--defaultborder);
            cursor: default;
          }
        }
      }

      .create-tab-crystal-cif-results-panel {
        padding: 0 20px 20px;
        border-radius: 5px;
        box-shadow: 0 0 5px 2px var(--defaultshadow);
        width: 73%;
        overflow: auto;
        scrollbar-width: thin;
        border: 1px solid var(--defaultborder);
        background: var(--defaultprimary);

        table,
        td {
          border: 1px solid var(--defaultborder);
        }

        table {
          table-layout: fixed;
          width: 100%;
        }

        thead,
        tfoot {
          background-color: var(--defaultprimary);
          color: var(--defaulttext);
        }

        h3 {
          font-size: 1.0em;
          border-bottom: 1px solid var(--defaultborder);
          margin-top: 25px;
          font-style: italic;
        }

        .data-item {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          p {
            word-break: break-word;
            margin-bottom: 1px;
          }
        }
      }

      &.open {
        display: flex;
        justify-content: space-between;
      }
    }

    .create-app-table {

      h2 {
        font-size: 26px;
        margin: 20px 0;
        text-align: center;
        small {
          font-size: 0.5em;
        }
      }

      .responsive-table {
        li {
          border-radius: 3px;
          padding: 25px 30px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 25px;
        }
        .table-header {
          background-color: var(--defaultborder);
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
        }
        .table-row {
          background-color: var(--defaultprimary);
          box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
        }
        .col-1 {
          flex-basis: 10%;
        }
        .col-2 {
          flex-basis: 40%;
        }
        .col-3 {
          flex-basis: 25%;
        }
        .col-4 {
          flex-basis: 25%;
        }

        @media all and (max-width: 767px) {
          .table-header {
            display: none;
          }
          .table-row{

          }
          li {
            display: block;
          }
          .col {

            flex-basis: 100%;

          }
          .col {
            display: flex;
            padding: 10px 0;
            &:before {
              color: #6C7A89;
              padding-right: 10px;
              content: attr(data-label);
              flex-basis: 50%;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
