.dashboard {
    height: 100%;
    overflow: hidden;
    grid-area: main;
    display: flex;
}

.left-panel {
    height: 100%;
    width: 23%;
    border-left: dimgrey;
    float: left;
    background: var(--defaultsecondary);
    padding: 30px;
}

.application-bay {
    width: 100%;
    height: 100%;
    background: var(--defaulttertiary);
}

#left-side-bar {
    width: 65px;
    min-width: 65px;
    background: var(--defaultprimary);
    border-right: 1px solid var(--defaultborder);
    float: left;
    z-index: 1;
    overflow-x: hidden;
    display: grid;
    align-content: space-between;
    padding-top: 60px;
    padding-bottom: 20px;
    /*
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    -webkit-transition:width .2s linear;
    transition:width .2s linear;
    -webkit-transform:translateZ(0) scale(1,1);

    &:hover {
        width: 200px;
        -moz-transition: opacity 0.5s ease-in-out;
        -ms-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        -webkit-transition:width .2s linear;
        transition:width .2s linear;
        -webkit-transform:translateZ(0) scale(1,1);
    }
    */
}

.left-side-bar-button {
    width: 100%;
    height: 55px;
    padding: 5px;
    text-align: center;
    border: none;
    margin: 5px 0;
    background: inherit;
    color: var(--defaulttext);
    border-left: 3px solid transparent;

    &.selected {
        border-left: 3px solid var(--defaulttext);
    }

    &:hover {
        /*background-color: rgb(232, 232, 232);*/
        border-left: 3px solid var(--defaulthover);
        color: var(--defaulthover);

        span {
            /*background-color: rgb(232, 232, 232);*/
            color: var(--defaultIcon);
        }
    }
}

#left-side-bar > button span {
    display: inline-block;
    text-align: center;
    transform: rotate(-90deg);
    font-size: 10px;
    color: var(--defaulttext);
    overflow: visible;
    position: relative;
    width: 10px;
}

.options-panel {
    height: 100%;
    width: 0;
    background: var(--defaultsecondary);
    border: 1px solid var(--defaultborder);
    border-top: none;
    grid-area: panel;
}

#left-task-bar {
    height: 100%;
    display: grid;
    grid-template-areas: 'panel app1 app2';
    grid-gap: 0;
    padding: 0;
    grid-template-rows: calc(100vh - 137px);
    grid-area: task-bar;
}

#view-app-container {
    width: 100%;
    height: 100%;
    display: flex;
}

#molecule-plot-container {
    order: 1;
    flex-grow: 1.5;
    border-right: 1px solid var(--defaultborder);
}

#cif-editor-container {
    order: 2;
    flex-grow: 1;
    min-width: 30%;
}

#compare-app-container {
    height: 100%;
    display: flex;
    max-width: calc(100vw - 325px);
}

#database-app-container {
    width: 100%;
    height: 100%;
    display: flex;
}

#plot-tab-set {
    height: 100%;
}

#app-root-loading, #plot-app-placeholder {
    display: grid;
    align-content: center;
    justify-content: center;
    height: 100%;
}

#plot-app-placeholder-message {
    padding: 50px;
    border-radius: 10px;
    font-family: 'Lato', serif;
    color: var(--defaultborder);
    border: solid 2px var(--defaultborder);
    > ul {
        font-size: 2.5rem;
        list-style: disc;
    }

    h1 {
        font-family: 'Lato', 'roboto', sans-serif;
    }
}

#x-axis {
    height: 40px;
    background: transparent;
    border-top: 1px solid var(--defaulttext);
    bottom: 30px;
    width: 90vw;
    z-index: 1;
    position: relative;
    left: 50px;
}

#y-axis {
    height: 90vh;
    background: transparent;
    border-right: 1px solid var(--defaulttext);
    width: 40px;
    z-index: 1;
    position: relative;
    left: 10px;
    bottom: 92%;
}

#xy-axis-outer {
    position: absolute;
    bottom: 20px;
    z-index: 2;
    width: 14vw;
    height: 15vh;
    display: grid;
    grid-template:
            "tick10 axis axis"
            "ylabel axis axis"
            "tick00 xlabel tick01";
    grid-template-columns: 40px auto auto;
    grid-template-rows: auto auto 40px;
    left: 50px;
    padding: 4px;

    &:hover {
        background: var(--defaultprimary);
        border-radius: 4px;
        border: 1px solid var(--defaultborder);
    }
}

#xy-axis-inner {
    width: 10vw;
    height: 10vh;
    border-bottom: 1px solid var(--defaulttext);
    border-left: 1px solid var(--defaulttext);
    grid-area: axis;
}

#x-label {
    grid-area: xlabel;
    font-size: 10px;
    margin-bottom: 0;
    justify-self: center;
}

#y-label {
    grid-area: ylabel;
    font-size: 10px;
    margin-bottom: 0;
}

#tick-upper-left {
    grid-area: tick10;
}

#tick-lower-left {
    grid-area: tick00;
}

#tick-lower-right {
    grid-area: tick01;
    float: right;

    > p {
        float: right;
    }
}

.tick > p {
    font-size: 11px;
}

.no-atom-geometry-content {
    display: grid;
    justify-content: center;
    align-content: center;
}